import React from 'react'
import Title from '../layouts/Title'
import { illustration} from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="Jetez un oeil sur Gitlab !"
          des="Mes projets"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Reconnaissance d'images"
          des="L'application détecte des images qui apparaissent à l'écran afin d'effectuer des actions dans un environnement 2D"
          src={illustration}
          githubLink="https://gitlab.com/william.arbeloa/macro-dofus"
          websiteLink="https://gitlab.com/william.arbeloa/macro-dofus"

        />
      </div>
    </section>
  );
}

export default Projects