import { AiFillAppstore, AiFillBulb } from "react-icons/ai";
import {  FaMobileAlt, FaVirus} from "react-icons/fa";


// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <AiFillBulb />,
    title: "Conseils informatique",
    des: "Je vous conseille pour l'achat ou le montage d'un ordinateur ou d'un smartphone",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "Configuration",
    des: "J'installe et configure un système d'exploitation (Windows ou Linux) sur votre machine",
  },
  {
    id: 3,
    icon: <FaVirus />,
    title: "Nettoyage",
    des: "Je retire ce qui ralentit votre PC, nettoie les virus, etc",
  },
  {
    id: 4,
    icon: <FaMobileAlt />,
    title: "Debloat",
    des: "Je supprime de vos appareils Android toutes les applications pré-installées",
  },
];
